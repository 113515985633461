var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-link',{staticClass:"brand-logo"},[(_vm.empresaLogo)?_c('b-img',{attrs:{"src":_vm.empresaLogo,"alt":"logo"}}):_vm._e()],1),_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Bem vindo ! ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Acesse sua conta ")]),_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.login($event)}}},[_c('b-form-group',{attrs:{"label-for":"usuario","label":"Usuário"}},[_c('validation-provider',{attrs:{"name":"usuario","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"usuario","name":"login-usuario","state":errors.length > 0 ? false:null,"placeholder":"john@example.com","autofocus":""},model:{value:(_vm.impersonate),callback:function ($$v) {_vm.impersonate=$$v},expression:"impersonate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"email","label":"Email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","name":"login-email","state":errors.length > 0 ? false:null,"placeholder":"john@example.com","autofocus":""},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Senha")]),_c('b-link',{attrs:{"to":{name:'recuperar-senha'}}},[_c('small',[_vm._v("Esqueceu a senha?")])])],1),_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"login-password","placeholder":"Senha","autocomplete":"off"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"variant":"primary","type":"submit","block":"","disabled":invalid}},[_vm._v(" Entrar ")])],1)]}}])}),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('small',[_vm._v(" This site is protected by reCAPTCHA and the Google "),_c('a',{attrs:{"href":"https://policies.google.com/privacy"}},[_vm._v("Privacy Policy")]),_vm._v(" and "),_c('a',{attrs:{"href":"https://policies.google.com/terms"}},[_vm._v("Terms of Service")]),_vm._v(" apply")])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }