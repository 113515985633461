<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <b-img
            v-if="empresaLogo"
            :src="empresaLogo"
            alt="logo"
          />
        </b-link>

        <b-card-title class="mb-1">
          Bem vindo !
        </b-card-title>
        <b-card-text class="mb-2">
          Acesse sua conta
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >

            <b-form-group
              label-for="usuario"
              label="Usuário"
            >
              <validation-provider
                #default="{ errors }"
                name="usuario"
                rules="required|email"
              >
                <b-form-input
                  id="usuario"
                  v-model="impersonate"
                  name="login-usuario"
                  :state="errors.length > 0 ? false:null"
                  placeholder="john@example.com"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- email -->
            <b-form-group
              label-for="email"
              label="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="john@example.com"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Senha</label>
                <b-link :to="{name:'recuperar-senha'}">
                  <small>Esqueceu a senha?</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Senha"
                    autocomplete="off"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
            >
              Entrar
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <small> This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply</small>
        </b-card-text>

      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, VBTooltip, BImg,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import useJwt from '@/auth/jwt/useJwt'
import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'

Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_GOOGLE_CAPTCHA_SITE_KEY,
  loaderOptions: {
    autoHideBadge: true,
  },
})

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BImg,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],

  data() {
    return {
      userEmail: '',
      password: '',
      token: '',
      impersonate: '',
      // validation rules
      required,
      email,
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  setup() {
    const { empresa, empresaLogo } = useAppConfig()
    const { appName } = $themeConfig.app

    return {
      empresa,
      empresaLogo,
      appName,
    }
  },

  methods: {

    recaptcha() {
      const self = this
      this.$recaptchaLoaded().then(() => {
        this.$recaptcha('login').then(token => {
          this.token = token
          useJwt.login({
            email: this.userEmail,
            senha: this.password,
            token: this.token,
            impersonate: this.impersonate,
          }).then(response => {
            useJwt.setToken(response.data.token)
            useJwt.setImpersonate(self.impersonate)
            localStorage.setItem('userData', JSON.stringify(response.data))

            const products = []

            response.data.ability.forEach(value => {
              if (value.subject === 'assinante') {
                products.push('spot')
              } else if (value.subject === 'assinante-futures') {
                products.push('futures')
              }
            })

            localStorage.setItem('cb-context', response.data.contexto.toLowerCase())
            localStorage.setItem('cb-products', JSON.stringify(products))

            self.$ability.update(response.data.ability)
            this.$router.replace(getHomeRouteForLoggedInUser(response.data.perfil))
          }).catch(() => {
            this.$swal({
              title: 'Erro!',
              text: ' E-mail e/ou senha incorretos.',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
        })
      })
    },

    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.recaptcha()
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
